import React, { useState, useRef} from "react";
import emailjs from '@emailjs/browser';
import '../../sass/Contact.css';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contactImg from '../../img/subpagesHero/kontakt_01.png';
import HeadImg from "../smallComponents/HeadImg";
import tarnowMap from "../../img/locationMap/tarnowMap.png";
import gdanskMap from "../../img/locationMap/gdanskMap.png";
import lodzMap from "../../img/locationMap/lodzMap.png";
import wroclawMap from "../../img/locationMap/wroclawMap.png";
import tarnowMobileMap from "../../img/mobileMap/tarnowMobile.svg";
import lodzMobileMap from "../../img/mobileMap/lodzMobile.svg";
import wroclawMobileMap from "../../img/mobileMap/wroclawMobile.svg";
import gdanskMobileMap from "../../img/mobileMap/gdanskMobile.svg";
import BetterButton from "../smallComponents/BetterButton";
import {useTranslation} from "react-i18next";

const Contact = () => {

    const {t} = useTranslation();

    const locationMaps = [tarnowMap, gdanskMap, lodzMap, wroclawMap];
    const [activeIndex, setActiveIndex] = useState(0);
    const [displayMap, setDisplayMap] = useState(locationMaps[0]);

    let [emailName, setEmailName] = useState('');
    let [emailSurname, setEmailSurname] = useState('');
    let [emailEmail, setEmailEmail] = useState('');
    let [emailPhone, setEmailPhone] = useState('');
    let [emailMess, setEmailMess] = useState('');

    const handleLocationClick = (index) => {
        setDisplayMap(locationMaps[index]);
        setActiveIndex(index);
    };

    const handleEmailNameChange = (event) => {
        setEmailName(event.target.value);
    }

    const handleEmailSurnameChange = (event) => {
        setEmailSurname(event.target.value);
    }

    const handleEmailEmailChange = (event) => {
        setEmailEmail(event.target.value);
    }

    const handleEmailPhoneChange = (event) => {
        setEmailPhone(event.target.value);
    }

    const handleEmailMessChange = (event) => {
        setEmailMess(event.target.value);
    }


    const form = useRef();

    const displayErrorNotification = (errorMessage) => {
        toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const clearEmailInputs = () => {
        setEmailName('');
        setEmailSurname('');
        setEmailEmail('');
        setEmailPhone('');
        setEmailMess('');
    }

    const sendEmail = async (e) => {

        e.preventDefault();
        if (emailName === '') {
            displayErrorNotification(t("emptyName"));
            return;
        }
        if (emailSurname === '') {
            displayErrorNotification(t("emptySurname"));
            return;
        }
        if (emailEmail === '') {
            displayErrorNotification(t("emptyEmail"));
            return;
        }
        if (emailPhone === '') {
            displayErrorNotification(t("emptyPhone"));
            return;
        }
        if (emailMess === '') {
            displayErrorNotification(t("emptyMessage"));
            return;
        }

        try {
            await toast.promise(emailjs.sendForm('service_h63qndh', 'template_5gjepwm', form.current, 'waVxrD8TopTbhDQpF'), {
                pending: {
                    render: () => t("sendingContact"),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    delay: 0,
                    theme: 'colored',
                }, success: {
                    render: () => t("successContact"),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    delay: 0,
                    theme: 'colored',
                }, error: {
                    render: () => t("errorContact"),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    autoClose: 5000,
                    delay: 0,
                    theme: 'colored',
                },
            });
            clearEmailInputs();


        } catch (error) {
            console.log(error.text);
        }
    };

    return (<div>
        <HeadImg src={contactImg}/>
        <div className="contactContainerBg">
            <div className="contactContainer">
                <h2 className="contactH2">{t("ContactH2")}</h2>
                <div className="contactContainer-elements">
                    <div className="contactContainer-elements-cities">
                        <div className={`contactContainer-elements-cities__city`}
                             onMouseEnter={() => handleLocationClick(0)}>
                            <div onClick={() => {
                                window.open('https://goo.gl/maps/rDrjFHvBPCFYWa6z7')
                            }}
                                className={`contactContainer-elements-cities__city__info ${activeIndex === 0 ? 'active' : ''}`}>
                                <div>
                                    <p className="cityName">Tarnów</p>
                                    <p>ul. Gumniska 10</p>
                                    <p>kontakt@uscars.pl</p>
                                    <p>+48 502 603 263 - Kuba</p>
                                    <p>+48 797 876 886 - Radek</p>
                                </div>
                                <img className="mobileMap" src={tarnowMobileMap} alt="Map of poland with the city of Tarnow marked"/>
                            </div>
                            <div
                                className={`contactContainer-elements-cities__city__lines ${activeIndex === 0 ? 'activeLine' : ''}`}>
                                <div className="cityLine-blue"></div>
                                <div className="cityLine-red"></div>
                            </div>
                        </div>
                        <div className={`contactContainer-elements-cities__city`}
                             onMouseEnter={() => handleLocationClick(1)}>
                            <div onClick={() => {window.open('https://goo.gl/maps/RW9Hxo3upBLaZg4M7')}}
                                className={`contactContainer-elements-cities__city__info ${activeIndex === 1 ? 'active' : ''}`}>
                                <div>
                                    <p className="cityName">Trójmiasto</p>
                                    <p>ul. Oliwska 77</p>
                                    <p>Chwaszczyno 80-209</p>
                                    <p>gdansk@uscars.pl</p>
                                    <p>+48 512 519 912 - Kamil</p>
                                </div>
                                <img className="mobileMap" src={gdanskMobileMap} alt="Map of poland with the city of Tarnow marked"/>
                            </div>
                            <div
                                className={`contactContainer-elements-cities__city__lines ${activeIndex === 1 ? 'activeLine' : ''}`}>
                                <div className="cityLine-blue"></div>
                                <div className="cityLine-red"></div>
                            </div>
                        </div>
                        <div className="contactContainer-elements-cities__city"
                             onMouseEnter={() => handleLocationClick(2)}>
                            <div
                                onClick={() => {window.open('https://goo.gl/maps/Eu6238k1wrtSerBv7')}}
                                className={`contactContainer-elements-cities__city__info ${activeIndex === 2 ? 'active' : ''}`}>
                                <div>
                                    <p className="cityName">Łódź</p>
                                    <p>ul. Wacława 15</p>
                                    <p>lodz@uscars.pl</p>
                                    <p>+48 789 755 099 - Michał</p>
                                </div>
                                <img className="mobileMap" src={lodzMobileMap} alt="Map of poland with the city of Tarnow marked"/>
                            </div>
                            <div
                                className={`contactContainer-elements-cities__city__lines ${activeIndex === 2 ? 'activeLine' : ''}`}>
                                <div className="cityLine-blue"></div>
                                <div className="cityLine-red"></div>
                            </div>
                        </div>
                        <div className="contactContainer-elements-cities__city"
                             onMouseEnter={() => handleLocationClick(3)}>
                            <div
                                onClick={() => {window.open('https://goo.gl/maps/P7DnSuoAkWBC8iU27')}}
                                className={`contactContainer-elements-cities__city__info ${activeIndex === 3 ? 'active' : ''}`}>
                                <div>
                                    <p className="cityName">Wrocław</p>
                                    <p>ul. Klimasa 37</p>
                                    <p>wroclaw@uscars.pl</p>
                                    <p>+48 690 450 940 - Damian</p>
                                </div>
                                <img className="mobileMap" src={wroclawMobileMap} alt="Map of poland with the city of Tarnow marked"/>
                            </div>
                            <div
                                className={`contactContainer-elements-cities__city__lines ${activeIndex === 3 ? 'activeLine' : ''}`}>
                                <div className="cityLine-blue"></div>
                                <div className="cityLine-red"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={displayMap} alt="Poland map with marked uscars offices" className="bigMap"/>
                    </div>
                </div>
                <div className="procedureContainer-content__lines__bottom">
                    <div className="procedureContainer-content__lines__blue"></div>
                    <div className="procedureContainer-content__lines__red"></div>
                    <div className="procedureContainer-content__lines__blue"></div>
                </div>
            </div>
        </div>

        <section className="emailContainer">

            <h3>{t("ContactH3")}</h3>
            <form ref={form} onSubmit={sendEmail}>
                <div>
                    <label>
                        {t("ContactName")}
                        <input type="text" name="user_name" value={emailName} onChange={handleEmailNameChange}/>
                    </label>
                    <label>
                        {t("ContactSurname")}
                        <input type="text" name="user_surname" value={emailSurname} onChange={handleEmailSurnameChange}/>
                    </label>
                    <label>
                        E-mail
                        <input type="email" name="user_email" value={emailEmail} onChange={handleEmailEmailChange}/>
                    </label>
                    <label>
                        {t("ContactPhone")}
                        <input type="number" name="user_phone" value={emailPhone} onChange={handleEmailPhoneChange}/>
                    </label>
                </div>

                <div>

                    <label>
                        {t("ContactMess")}
                        <textarea name="message" value={emailMess} onChange={handleEmailMessChange}/>
                    </label>
                    <div className="buttonContainer">
                        <BetterButton label={t("ContactButton")} type="submit" />
                    </div>
                    {/*<input type="submit" value="Wyślij wiadomość"/>*/}
                </div>
            </form>
            <div className="procedureContainer-content__lines__bottom">
                <div className="procedureContainer-content__lines__blue"></div>
                <div className="procedureContainer-content__lines__red"></div>
                <div className="procedureContainer-content__lines__blue"></div>
            </div>
        </section>

    </div>);
};

export default Contact;